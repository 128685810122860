import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { connect } from 'react-redux'
import { CardBody, Badge, Button, ButtonGroup, Container } from 'reactstrap'
import moment from 'moment'
import numeral from 'numeral'

import * as actions from 'api'
import LevelContainer from 'components/LevelContainer'
import Avatar from 'components/lib/Avatar'
import Info from './Info'
import Schedule from './Schedule'
import Badges from './Badges'
import DarkCard from './DarkCard'

const showAlphaFeatures = process.env.REACT_APP_SHOW_ALPHA_FEATURES === '1'

const ProfileName = styled.h3`
  color: #000;
  padding-top: 7px;
`

const ActionBar = styled(LevelContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const EmploymentHistory = styled.p`
  color: #777;
`

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const buttonStyle = (active, theme) => ({
  width: '33.3%',
  backgroundColor: active ? theme.colors.black : theme.colors.white,
  borderColor: theme.colors.primary,
  color: active ? theme.colors.white : theme.colors.black
})

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rSelected: 1
    }
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this)
  }

  async componentDidMount() {
    if (this.props.currentEmployment) {
      await this.props.getEmployees(this.props.currentEmployment.company._id)
      await this.props.getJobs()
    }
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected })
  }

  render() {
    const {
      profile,
      currentShift,
      currentEmployment,
      numJobs,
      jobs,
      theme
    } = this.props

    if (!currentEmployment) {
      return null
    }

    const { fitness, name } = currentEmployment

    const actionBar = (
      <ActionBar>
        <Title>
          <ProfileName>{(name && name.full) || (profile.name && profile.name.full)}</ProfileName>
          <EmploymentHistory>
            {`Started ${moment(profile.createdAt).fromNow()}`}
          </EmploymentHistory>
        </Title>
        {/* <div style={{ alignSelf: 'flex-start' }}>
          <Button
            className="cta btn"
            size={'md'}
            href={'/profile/edit'}
            color="primary"
            style={{ marginRight: 10 }}
          >
            Update Profile
          </Button>
          <Button href="/profile/companies/" className="cta btn" color="dark">
            Update Teams
          </Button>
        </div> */}
      </ActionBar>
    )

    const avatarBar = (
      <LevelContainer style={{ marginRight: '5%', marginBottom: 20 }}>
        <Avatar
          size={75}
          url={profile.avatar ? profile.avatar.url : ''}
          active={currentShift}
          name={currentEmployment.name && currentEmployment.name.full}
        />
        {showAlphaFeatures && (
          <InfoColumn>
            <p style={{ marginBottom: 2 }}>{numJobs}</p>
            <p style={{ marginBottom: 2 }}>Jobs</p>
          </InfoColumn>
        )}
        <InfoColumn>
          <p style={{ marginBottom: 2 }}>
            {fitness && fitness.calories
              ? numeral(fitness.calories).format('0a')
              : 0}
          </p>
          <p style={{ marginBottom: 2 }}>Calories</p>
        </InfoColumn>
        <InfoColumn>
          <p style={{ marginBottom: 2 }}>
            {fitness && fitness.steps ? numeral(fitness.steps).format('0a') : 0}
          </p>
          <p style={{ marginBottom: 2 }}>Steps</p>
        </InfoColumn>
      </LevelContainer>
    )

    const skillsBar = () => (
      <>
        <p style={{ marginBottom: 8 }}>Skills</p>
        {currentEmployment.skills.map((skill, index) => {
          return (
            <Badge
              pill
              color="primary"
              style={{
                padding: '3px 20px',
                margin: 4
              }}
              key={index}
            >
              {skill}
            </Badge>
          )
        })}
      </>
    )

    const contentContainer = () => {
      switch (this.state.rSelected) {
        case 1:
          return <Schedule jobs={assignedJobs} />
        case 2:
          return <Info {...this.props} />
        case 3:
          return <Badges {...this.props} />
        default:
          return <Info {...this.props} />
      }
    }

    const assignedJobs = (jobs || []).filter(
      job =>
        job.team.filter(
          teamMember =>
            profile && teamMember.user && teamMember.user._id === profile._id
        ).length > 0
    )

    return (
      <div className="full-height">
        <Container fluid style={{ marginTop: 80 }}>
          <div className="container" style={{ maxWidth: 600 }}>
          {actionBar}
            <DarkCard>
              <CardBody
                style={{
                  paddingLeft: 50,
                  paddingRight: 50
                }}
              >
                {avatarBar}
                {!!currentEmployment.skills.length && skillsBar()}
              </CardBody>
            </DarkCard>
          </div>
          <div
            className="container"
            style={{ maxWidth: 500, marginBottom: 20 }}
          >
            <ButtonGroup className="full-width" style={{ width: '100%' }}>
              <Button
                onClick={() => this.onRadioBtnClick(1)}
                active={this.state.rSelected === 1}
                style={buttonStyle(1 === this.state.rSelected, theme)}
              >
                Schedule
              </Button>
              <Button
                onClick={() => this.onRadioBtnClick(2)}
                active={this.state.rSelected === 2}
                style={buttonStyle(2 === this.state.rSelected, theme)}
              >
                Info
              </Button>
              {/* {showAlphaFeatures && (
                <Button
                  onClick={() => this.onRadioBtnClick(3)}
                  active={this.state.rSelected === 3}
                  style={buttonStyle(3 === this.state.rSelected, theme)}
                >
                  Badges
                </Button>
              )} */}
            </ButtonGroup>
          </div>
          {contentContainer()}
        </Container>
      </div>
    )
  }
}

Account.defaultProps = {
  numJobs: 100
}

const mapStateToProps = state => ({
  profile: state.user.profile,
  employment: state.user.employment,
  currentEmployment: state.user.currentEmployment,
  timezone: state.user.timezone,
  currentShift: state.currentShift,
  employees: state.employees.employees,
  jobs: state.jobs.jobs
})

export default connect(
  mapStateToProps,
  actions
)(withTheme(Account))
