import React from 'react'
import styled from 'styled-components'
import { FaSearch } from 'react-icons/fa'

const SearchInputGroup = styled.div`
  border-radius: 25px;
  position: relative;
  display: flex;
  flex-flow: nowrap;
  resize: vertical;
  width: 300px;
  min-width: 140px;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.dark2};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 1px 10px;
`

const SearchInputPrepend = styled.div`
  background: transparent;
`

const SearchInput = styled.input`
  background: transparent;
  color: ${props => props.background === 'light' ? 'black' : 'white'};
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
`

const SearchBar = ({value, onChange, background, style, onKeyPress, ...props}) => {
  const searchTextChange = targetVal => {
    if (onChange) {
      onChange(targetVal)
    }
  }

  return (
    <SearchInputGroup style={style}>
      <SearchInputPrepend addonType="prepend">
        <FaSearch          
          size={20}
          style={{ verticalAlign: 'sub' }}
        />
      </SearchInputPrepend>
      <SearchInput
        background={background || 'dark'}
        type="search"
        onChange={event => searchTextChange(event.target.value)}
        value={value}
        placeholder="Search"
        onKeyPress={onKeyPress}
      />
    </SearchInputGroup>
  )
}

export default SearchBar
