import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import * as actions from 'api'
import ProfileForm from './ProfileForm'

class Profile extends Component {
  submitProfile = async values => {
    if (!values.timezone) {
      values.timezone = moment.tz.guess()
    }

    const profile = {
      ...this.props.profile,
      ...values
    }

    await this.props.modifyUserProfile(profile)
    await this.props.updateProfile()

    this.props.history.push('/dashboard')
  }

  render() {
    const { profile } = this.props
    const title = profile
      ? `Update ${profile.name.full || profile.email}`
      : 'New Profile'

    return (
      <div className="container" style={{ marginTop: 76 }}>
        <h1 className="text-dark">{title}</h1>
        <ProfileForm onSubmit={this.submitProfile} profile={profile} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.user.profile
})

export default connect(
  mapStateToProps,
  actions
)(Profile)
