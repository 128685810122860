import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { change, Field, reduxForm } from 'redux-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, Modal, ModalBody } from 'reactstrap'
import * as actions from 'api'
import moment from 'moment-timezone'

import {
  DateField,
  DropdownField,
  MarkdownField,
  MultiSelectField,
  UploadField,
  InputField
} from 'components/fields'
import ErrorMessage from 'components/ErrorMessage'
import LocationModal from 'screens/Dashboard/components/modals/LocationModal'
import ClientModal from 'screens/Dashboard/components/modals/ClientModal'
import TemplateServiceModal from 'screens/Dashboard/components/modals/TemplateServiceModal'
import { required, image } from 'utils/validators'
import { trackEvent } from 'api/clientHub'
import {
  NEW_INSPECTION_SELECT_CLIENT,
  NEW_INSPECTION_CREATE_NEW_CLIENT
} from 'store/analyticsActions/types/inspectionsTypes'

import {
  JobItem,
  MerchantEmployeeItem,
  ClientItem,
  LocationItem
} from 'components/items'
import { JobCategories } from 'data'
import TasksField from './TasksField'
import InspectionRatings from './InspectionRatings'
import { ClapSpinner } from 'components/Loader/ClapSpinner'

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FieldContainer = styled.div`
  position: relative;
`

const StyledButton = styled(Button)`
  position: absolute;
  top: -7px;
  left: 60px;
`

const renderEmployeeName = item => {
  // Prioritize Employee record.

  if (item.name && item.name.full && item.name.full.length) {
    return item.name.full
  }

  return 'Name not found'
}

const JOB_TYPES = Object.freeze({
  INSPECTION: 'INSPECTION',
  WALKTHROUGH: 'WALKTHROUGH'
})

class JobForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      services: [],
      showLocationModal: false,
      showClientModal: false,
      showTemplateServiceModal: false
    }
  }

  static propTypes = {
    employees: PropTypes.arrayOf(PropTypes.object),
    clients: PropTypes.arrayOf(PropTypes.object),
    services: PropTypes.arrayOf(PropTypes.object)
  }

  static defaultProps = {
    employees: [],
    clients: [],
    services: []
  }

  async componentDidMount() {
    await this.props.getClients()
    await this.props.getServiceTemplates()
  }

  _toggleClientModal = () => {
    this.setState({
      showClientModal: !this.state.showClientModal
    })
  }

  _toggleLocationModal = () => {
    this.setState({
      showLocationModal: !this.state.showLocationModal
    })
  }

  _toggleTemplateServiceModal = () => {
    this.setState(prevState => ({
      showTemplateServiceModal: !prevState.showTemplateServiceModal
    }))
  }

  _saveLocation = async values => {
    const res = await this.props.submitLocation(values)
    await this.props.getClients()
    if (res && res.payload && res.payload.data) {
      this.props.change('Job', 'location', res.payload.data._id)
    }
    this._toggleLocationModal()
  }

  _saveClient = async values => {
    const { job } = this.props
    const res = await this.props.submitClient(values)

    if (res && res.payload && res.payload.data) {
      if (job?.type === JOB_TYPES.INSPECTION) {
        trackEvent({
          typeEvent: NEW_INSPECTION_CREATE_NEW_CLIENT
        })
      }
      this.props.change('Job', 'client', res.payload.data._id)
    }
    const clientsRes = await this.props.getClients()
    const newClient =
      clientsRes &&
      clientsRes.payload &&
      clientsRes.payload.data.find(
        client => client._id === res.payload.data._id
      )
    if (newClient && newClient.locations) {
      this.props.change('Job', 'location', newClient.locations[0]._id)
    }
    this._toggleClientModal()
  }

  _saveCategory = async values => {
    await this.props.submitServiceTemplate(values)
    await this.props.getServiceTemplates()
    this._toggleTemplateServiceModal()
  }

  _locationsOfClient = clientId => {
    const { clients } = this.props
    const theClient = clients.find(client => client._id === clientId)
    if (theClient) {
      return theClient.locations
    } else {
      return []
    }
  }

  _convertTimezone = date =>
    moment(date)
      .clone()
      .tz(this.props.localTimezone)

  _getLabelTimezone = localTimezone => {
    let timeZoneFormat = moment()
      .tz(localTimezone)
      .format('z')
    let pattern = new RegExp('^[0-9-+/]+$')

    let label = pattern.test(timeZoneFormat)
      ? `GMT${timeZoneFormat}`
      : timeZoneFormat

    return `Scheduled Date (${label})`
  }

  render() {
    let {
      clients,
      employees,
      services,
      error,
      localTimezone,
      values,
      job
    } = this.props

    const { type } = job
    const jobType = type ? type.toUpperCase() : ''

    if (!services || !services.length) {
      services = JobCategories
    }

    let errorMessage = null
    if (error) {
      errorMessage = <ErrorMessage error={error} />
    }

    let clientId = null
    if (values && values.client) {
      if (values.client._id) {
        clientId = values.client._id
      } else {
        clientId = values.client
      }
    }

    const validLocations = this._locationsOfClient(clientId)

    let jobTimezone = localTimezone
    if (values && values.scheduledAt) {
      if (validLocations) {
        const jobLocation = validLocations.find(
          location => location._id === values.location
        )

        if (
          jobLocation &&
          jobLocation.address &&
          jobLocation.address.timezone &&
          jobLocation.address.timezone.timeZoneId
        )
          values.timezone = jobLocation.address.timezone.timeZoneId

        jobTimezone = values.timezone
        // console.log(jobTimezone)
      }
    }

    const renderRatings = InspectionRatings.map((rating, index) => (
      <option value={rating} key={index}>
        {rating}
      </option>
    ))

    // don't filter out employees without user anymore
    // const validEmployees = employees.filter(employee => !!employee.user)

    const isInspection = jobType === JOB_TYPES.INSPECTION
    return (
      <>
        {jobType == '' ? (
          <ClapSpinner size={50} loading={true} />
        ) : (
          <div style={{ width: '100%' }}>
            <Modal
              isOpen={this.state.showTemplateServiceModal}
              toggle={this._toggleTemplateServiceModal}
            >
              <ModalBody>
                <TemplateServiceModal submitTemplate={this._saveCategory} />
              </ModalBody>
            </Modal>

            <LocationModal
              isOpen={this.state.showLocationModal}
              toggle={this._toggleLocationModal}
              submitLocation={this._saveLocation}
              clientId={clientId}
            />

            <ClientModal
              isOpen={this.state.showClientModal}
              toggle={this._toggleClientModal}
              submitClient={this._saveClient}
            />

            <div className="card">
              <div className="card-body">
                <form
                  onSubmit={this.props.handleSubmit}
                  encType="multipart/form-data"
                >
                  <FieldContainer>
                    <Field
                      name="client"
                      component={DropdownField}
                      data={clients}
                      itemComponent={ClientItem}
                      type="select"
                      label="Client"
                      textField={item => (item ? item.name : null)}
                      valueField={!isInspection && '_id'}
                      normalize={value =>
                        value ? (isInspection ? value : value._id) : null
                      }
                      validate={[required]}
                      warn={required}
                      filter={'contains'}
                      onChange={newValue => {
                        if (isInspection) {
                          trackEvent({
                            typeEvent: NEW_INSPECTION_SELECT_CLIENT,
                          })
                        }
                        if (
                          newValue &&
                          newValue.locations &&
                          newValue.locations.length
                        ) {
                          this.props.change(
                            'Job',
                            'location',
                            isInspection
                              ? newValue.locations[0]
                              : newValue.locations[0]._id
                          )
                        }
                      }}
                    />
                    <StyledButton
                      color="link"
                      onClick={this._toggleClientModal}
                    >
                      {' '}
                      create new{' '}
                    </StyledButton>
                  </FieldContainer>
                  {clientId && (
                    <FieldContainer>
                      <Field
                        name="location"
                        component={DropdownField}
                        itemComponent={LocationItem}
                        data={validLocations}
                        type="select"
                        label="Location"
                        textField={item =>
                          item && item.address ? item.address.fullAddress : null
                        }
                        normalize={value =>
                          value ? (isInspection ? value : value._id) : null
                        }
                        warn={required}
                        validate={required}
                        valueField={!isInspection && '_id'}
                      />
                      <StyledButton
                        color="link"
                        onClick={this._toggleLocationModal}
                      >
                        {' '}
                        create new{' '}
                      </StyledButton>
                    </FieldContainer>
                  )}
                  {!isInspection && (
                    <FieldContainer>
                      <Field
                        name="categories"
                        type="select"
                        component={MultiSelectField}
                        data={services}
                        itemComponent={JobItem}
                        label="Services"
                        textField="name"
                        valueField="name"
                        validate={[required]}
                        warn={required}
                      />
                      <StyledButton
                        color="link"
                        onClick={this._toggleTemplateServiceModal}
                      >
                        {' '}
                        create new{' '}
                      </StyledButton>
                    </FieldContainer>
                  )}
                  <FieldContainer>
                    <Field
                      name="scheduledAt"
                      component={DateField}
                      type="date"
                      label={this._getLabelTimezone(localTimezone)}
                      timezone={jobTimezone}
                      localTimezone={localTimezone}
                    />
                  </FieldContainer>

                  <Field
                    name="team"
                    component={isInspection ? DropdownField : MultiSelectField}
                    data={employees}
                    label={
                      isInspection ? 'Select Inspector' : 'Select Team Members'
                    }
                    type="select"
                    itemComponent={MerchantEmployeeItem}
                    textField={renderEmployeeName}
                    valueField={!isInspection && '_id'}
                    validate={isInspection ? required : null}
                    _onBlur={() => null}
                  />

                  {/* {job && job.type === 'Inspection' && (
                      <Field
                        name="rating"
                        component={InputField}
                        type="select"
                        label="Rating"
                      >
                        {renderRatings}
                      </Field>
                    )} */}
                  {!isInspection && (
                    <TasksField job={this.props.job} label="Tasks" />
                  )}

                  <Field
                    name="notes"
                    component={MarkdownField}
                    type="textarea"
                    label="Notes"
                    height={'12vh'}
                  />

                  {!isInspection && (
                    <Field
                      name="attachments"
                      component={UploadField}
                      type="file"
                      label="Add Attachments"
                      multiple
                      validate={image}
                      padding={2}
                    />
                  )}
                  <SubmitContainer>
                    {errorMessage}
                    <button
                      type="button"
                      className="btn btn-default"
                      style={{ marginRight: 10 }}
                      onClick={this.props.history.goBack}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-dark">
                      {isInspection ? 'Next' : 'Done'}
                    </button>
                  </SubmitContainer>
                </form>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  employees: state.employees.employees,
  clients: state.clients.clients,
  services: state.library.services,
  localTimezone: state.user.localTimezone,
  values: state.form.Job ? state.form.Job.values : null,
  error: state.jobs.error
})

export default compose(
  reduxForm({ form: 'Job' }),
  connect(mapStateToProps, { ...actions, change })
)(JobForm)
