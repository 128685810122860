import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from 'api'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { FiMoreHorizontal } from 'react-icons/fi'
import DateWithTooltip from 'components/DateWithTooltip'
import DataScreen from 'components/DataScreen'

class ArchivedClients extends Component {
  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  unarchiveClient = async client => {
    const unarchived = { ...client, archived: false }
    await this.props.unArchiveClient(unarchived)

    if (this.dataScreen.current) {
      this.dataScreen.current.forceFetch()
    }
  }

  render() {
    const columns = [
      { header: 'Name', accessor: 'name' },
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        }
      },
      {
        header: 'Options',
        render: client => (
          <UncontrolledDropdown>
            <DropdownToggle color="link">
              <FiMoreHorizontal size={24} color="black" />
            </DropdownToggle>
            <DropdownMenu right>
              <>
                <DropdownItem onClick={() => this.unarchiveClient(client)}>
                  Restore
                </DropdownItem>
              </>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    ]

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <h3
          style={{
            color: 'white',
            marginTop: 20,
            textAlign: 'center'
          }}
        >
          Archived Clients
        </h3>
        <DataScreen
          ref={this.dataScreen}
          entity="Archived Client"
          url="/api/clients/find?archived=true"
          columns={columns}
        />
      </div>
    )
  }
}

export default connect(null, actions)(ArchivedClients)
