import React from 'react'
import { connect } from 'react-redux'
import { getEstimates, archiveEstimate } from 'api'
import {
  Container,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button
} from 'reactstrap'
import { toast } from 'react-toastify'
import { connectConfirmation } from 'components/confirm'
import DateWithTooltip from 'components/DateWithTooltip'
import Icon from 'components/icons'
import networkErrorToast from 'utils/networkErrorToast'
import { ActionButtons } from 'components/lib/Button'
import DataScreen from 'components/DataScreen'

import ActionBar from '../../components/ActionBar'
import LabelColumn from '../../components/LabelColumn'
import FilterForm from '../../components/forms/FilterForm'
import { fullName } from 'utils'

class EstimatesList extends React.Component {
  state = {}

  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  createEstimate = () => {
    this.props.history.push('/dashboard/new-estimate')
  }

  createQuickBid = () => {
    this.props.history.push('/dashboard/quickbid/')
  }

  _archiveEstimate = async id => {
    let removed = await this.props.archiveEstimate(id)

    if (removed.error) {
      return networkErrorToast(removed)
    } else {
      toast('Estimate was archived.')
      if (this.dataScreen.current) {
        this.dataScreen.current.forceFetch()
      }
    }
  }

  _showArchivedEstimates = () => {
    this.props.history.push('/dashboard/estimates/archived/')
  }

  _editEstimate = estimate => {
    if (estimate.type && estimate.type === 'quickbid') {
      return this.props.history.push(`/dashboard/quickbid/${estimate._id}`)
    }
    this.props.history.push(`/dashboard/edit-estimate/${estimate._id}`)
  }

  stopEventBubbling = e => {
    e.cancelBubble = true
    if (e.stopPropagation) {
      e.stopPropagation()
    }
  }

  render() {
    const columns = [
      {
        header: 'Labels',
        width: '5%',
        render: item => {
          return (
            <LabelColumn
              id={item._id}
              services={item.services}
              labels={item.labels}
            />
          )
        }
      },

      {
        header: 'Type',
        render: item =>
          item.type && item.type === 'quickbid' ? 'QuickBid' : 'Estimate',
        sortField: 'type',
        sortable: true
      },

      {
        header: 'Name',
        render: item => `${item.code}${item.name ? ' - ' + item.name : ''}`,
        sortable: true,
        sortField: 'code'
      },

      {
        header: 'Client',
        accessor: 'client.name',
        sortable: true
      },
      {
        header: 'Created By',
        render: item =>
          (item?.createdBy?.name && fullName(item.createdBy.name)),
        sortField: 'createdBy.name',
        sortable: true
      },
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        },
        sortField: 'createdAt',
        sortable: true
      },

      {
        header: 'Options',
        render: item => (
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              onClick={e => {
                this.stopEventBubbling(e)
              }}
            >
              <Icon icon="IoIosMore" color="black" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => this._editEstimate(item)}>
                Edit
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                className="text-danger"
                onClick={e => {
                  this.stopEventBubbling(e)
                  this.props.confirm(
                    'Confirm Archive',
                    'Are you sure you want to archive this estimate?',
                    () => this._archiveEstimate(item._id)
                  )
                }}
              >
                Archive
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    ]

    return (
      <Container fluid style={{ marginTop: 20 }}>
        <ActionBar>
          <div />
          <div>
            <FilterForm
              applyFilters={data =>
                this.setState({
                  filterData: data
                })
              }
              serviceFilters
              locationFilters
              estimateFilters
            />
            <ActionButtons
              buttons={[
                {
                  component: (
                    <Button color="secondary" onClick={this.createQuickBid}>
                      Create QuickBid
                    </Button>
                  )
                },
                {
                  title: 'Create Estimate',
                  onClick: this.createEstimate
                }
              ]}
              options={[
                {
                  title: 'See archived estimates',
                  onClick: this._showArchivedEstimates
                }
              ]}
            />
          </div>
        </ActionBar>
        <DataScreen
          ref={this.dataScreen}
          entity="Estimate"
          url="/api/estimates/find"
          onRowClick={this._editEstimate}
          columns={columns}
          filterData={this.state.filterData}
        />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  estimates: state.estimates.estimates,
  loading: state.estimates.loading,
  profile: state.user.profile
})

export default connect(mapStateToProps, { getEstimates, archiveEstimate })(
  connectConfirmation(EstimatesList)
)
