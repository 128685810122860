import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from 'api'
import networkErrorToast from 'utils/networkErrorToast'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { FiMoreHorizontal } from 'react-icons/fi'
import DateWithTooltip from 'components/DateWithTooltip'
import DataScreen from 'components/DataScreen'
import { toast } from 'react-toastify'
import moment from 'moment'
import { connectConfirmation } from 'components/confirm'

class InvitedEmployees extends Component {
  state = {}

  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  _reinviteEmployee = async id => {
    let reinvited = await this.props.reinviteEmployee(id)

    if (reinvited.error) {
      return networkErrorToast(reinvited)
    } else {
      toast('Resent invite.')
      if (this.dataScreen.current) {
        this.dataScreen.current.forceFetch()
      }
    }
  }

  editEmployee = id =>
    this.props.history.push(`/dashboard/employees/${id}/edit`)

  removeEmployee = employee => {
    this.props.confirm(
      'Confirm Archive',
      `Are you sure you want to archive ${
        employee.name && employee.name.first
          ? employee.name.first
          : 'this employee'
      }?`,
      async () => {
        let removed = await this.props.removeEmployee(employee._id)

        if (removed.error) {
          return networkErrorToast(removed)
        } else {
          if (this.dataScreen.current) {
            this.dataScreen.current.forceFetch()
          }
        }
      }
    )
  }

  render() {
    const columns = [
      { header: 'Email', accessor: 'email' },
      { header: 'Role', accessor: 'role' },
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        }
      },
      {
        header: 'Invite Expires',
        render: item => {
          const { _id, invite } = item
          if (moment().isAfter(moment(invite.expires))) {
            return <span className="text-danger">expired</span>
          }
          return <DateWithTooltip id={`${_id}-expirey`} date={invite.expires} />
        }
      },
      {
        header: 'Options',
        render: employee => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle color="link">
                <FiMoreHorizontal size={24} color="black" />
              </DropdownToggle>
              <DropdownMenu right>
                <>
                  <DropdownItem onClick={() => this.editEmployee(employee._id)}>
                    Edit
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => this._reinviteEmployee(employee._id)}
                  >
                    Resend Invite
                  </DropdownItem>
                  <DropdownItem
                    className="text-danger"
                    onClick={() => this.removeEmployee(employee)}
                  >
                    Archive
                  </DropdownItem>
                </>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      }
    ]

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <h3
          style={{
            color: 'white',
            marginTop: 20,
            textAlign: 'center'
          }}
        >
          Invited Employees
        </h3>
        <DataScreen
          ref={this.dataScreen}
          entity="Invited Employee"
          url="/api/employees/find?invited=true"
          columns={columns}
          hideSpinner={true}
        />
      </div>
    )
  }
}

export default connect(null, actions)(connectConfirmation(InvitedEmployees))
