import React, { Component } from 'react'
import {
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import BusyButton from './BusyButton'

class ActionButtons extends Component {
  state = {}

  toggle = () => {
    const { dropdownOpen } = this.state
    this.setState({ dropdownOpen: !dropdownOpen })
  }

  render() {
    const { buttons, options, color, ...rest } = this.props
    if (!buttons && !options) {
      return null
    }

    return (
      <ButtonGroup {...rest}>
        {buttons &&
          buttons.length &&
          buttons.map((button, index) => {
            if (button.component) {
              return <React.Fragment key={`button${index}`}>{button.component}</React.Fragment>
            }

            return (
              <BusyButton
                key={`button${index}`}
                onClick={button.onClick}
                color={button.color || 'dark'}
                busy={button.busy}
                disabled={button.disable}
              >
                {button.icon ? button.icon : null}
                {button.title || 'Button'}
              </BusyButton>
            )
          })}
        {options && options.length > 0 && (
          <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret color={color || 'dark'} />
            <DropdownMenu right>
              {options.map((option, index) => {
                return (
                  <div key={`option${index}`}>
                    {option.divide && <DropdownItem divider />}
                    <DropdownItem
                      disabled={option.disable}
                      onClick={option.onClick}
                    >
                      <span className={`text-${option.color || 'default'}`}>
                        {option.title || `Option ${index + 1}`}
                      </span>
                    </DropdownItem>
                  </div>
                )
              })}
            </DropdownMenu>
          </ButtonDropdown>
        )}
      </ButtonGroup>
    )
  }
}

export default ActionButtons
