import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { FaFilter } from 'react-icons/fa'
import SideBar from 'components/SideBar'
import { axiosClient } from 'store'
import LocationTypes from 'data/LocationTypes'
import BadgeSection from './BadgeSection'
import {
  proposalData,
  employeeRoles,
  clientActive,
  estimateTypes
} from './filterData'
import _ from 'lodash'

const FilterForm = ({
  applyFilters,
  locationFilters,
  serviceFilters,
  employeeFilters,
  clientFilters,
  estimateFilters
}) => {
  const [showSideBar, setShowSideBar] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [labelData, setLabelData] = useState([])
  const [serviceData, setServiceData] = useState([])
  const [filtersApplied, setFiltersApplied] = useState(false)

  const fetchLabels = async () => {
    const { data } = await axiosClient.get('/api/labels')
    if (data) {
      setLabelData(data)
    }
  }

  const fetchServices = async () => {
    const { data } = await axiosClient.get('/api/library/services')
    if (data) {
      setServiceData(data)
    }
  }

  return (
    <>
      <Button
        color="white"
        style={{ marginRight: 8 }}
        onClick={async () => {
          await fetchLabels()
          await fetchServices()
          setShowSideBar(true)
        }}
      >
        <FaFilter color={filtersApplied ? '#09f415' : 'dark'} />
      </Button>
      {showSideBar && (
        <SideBar
          isOpen={showSideBar}
          onClose={() => setShowSideBar(false)}
          resizable
        >
          {clientFilters ? (
            <BadgeSection
              switchSection
              data={clientActive}
              colorField="color"
              filterField="name"
              filteredData={filterData.estimate || []}
              setFilterData={data => {
                let newData = {
                  ...filterData,
                  client: data
                }
                if (!data || !data.length) {
                  delete newData.client
                }
                setFilterData(newData)
              }}
            />
          ) : null}
          {estimateFilters ? (
            <BadgeSection
              switchSection
              data={estimateTypes}
              colorField="color"
              filterField="name"
              filteredData={filterData.type || []}
              setFilterData={data => {
                let newData = {
                  ...filterData,
                  type: data
                }
                if (!data || !data.length) {
                  delete newData.type
                }
                setFilterData(newData)
              }}
            />
          ) : null}
          <BadgeSection
            data={proposalData}
            colorField="color"
            filterField="name"
            filteredData={filterData.proposal || []}
            setFilterData={data => {
              let newData = {
                ...filterData,
                proposal: data
              }
              if (!data || !data.length) {
                delete newData.proposal
              }
              setFilterData(newData)
            }}
          />
          {employeeFilters ? (
            <BadgeSection
              data={employeeRoles}
              filterField="name"
              sectionTitle="Filter by role"
              filteredData={filterData.roles || []}
              setFilterData={data => {
                let newData = {
                  ...filterData,
                  roles: data
                }
                if (!data || !data.length) {
                  delete newData.roles
                }
                setFilterData(newData)
              }}
            />
          ) : null}
          <BadgeSection
            data={labelData}
            sectionTitle="Filter by custom labels"
            colorField="color"
            filterField="_id"
            filteredData={filterData.labels || []}
            setFilterData={data => {
              let newData = {
                ...filterData,
                labels: data
              }
              if (!data || !data.length) {
                delete newData.labels
              }
              setFilterData(newData)
            }}
          />
          {serviceFilters ? (
            <BadgeSection
              data={serviceData}
              sectionTitle="Filter by service"
              colorField="labelColor"
              filterField="name"
              filteredData={filterData.services || []}
              setFilterData={data => {
                let newData = {
                  ...filterData,
                  services: data
                }
                if (!data || !data.length) {
                  delete newData.services
                }
                setFilterData(newData)
              }}
            />
          ) : null}
          {locationFilters ? (
            <BadgeSection
              data={LocationTypes}
              sectionTitle="Filter by location type"
              filterField="name"
              filteredData={filterData.locationTypes || []}
              setFilterData={data => {
                let newData = {
                  ...filterData,
                  locationTypes: data
                }
                if (!data || !data.length) {
                  delete newData.locationTypes
                }
                setFilterData(newData)
              }}
            />
          ) : null}
          <div style={{ display: 'flex' }}>
            <Button
              size="sm"
              color="danger"
              style={{ marginRight: 8 }}
              onClick={() => {
                setFilterData({})
                applyFilters({})
                setFiltersApplied(false)
              }}
            >
              Clear Filters
            </Button>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                applyFilters(filterData)
                if (_.isEmpty(filterData)) {
                  setFiltersApplied(false)
                } else {
                  setFiltersApplied(true)
                }
              }}
            >
              Apply Filters
            </Button>
          </div>
        </SideBar>
      )}
    </>
  )
}

export default FilterForm
