import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import networkErrorToast from 'utils/networkErrorToast'
import {
  Button,
  ButtonGroup,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import _ from 'lodash'

import styled from 'styled-components'
import DateWithTooltip from 'components/DateWithTooltip'
import StatusIcon from 'components/StatusIcon'
import DataScreen from 'components/DataScreen'
import {
  createProposal,
  getWalkthrough,
  getWalkthroughs,
  createWalkthrough,
  cloneWalkthrough,
  cancelWalkthrough,
  completeWalkthrough,
  archiveWalkthrough,
  startWalkthrough,
  updateWalkthrough,
  updateLocation,
  createEstimate,
  getServiceTemplates
} from 'api'
import { axiosClient } from 'store'
import { amplitudeInstance } from '../../../../store/middleware/analytics'

import { FaTrello, FaCalendar, FaTable } from 'react-icons/fa'

import JobDetailedView from '../../Jobs/components/JobDetailedView'
import CardsView from '../../Jobs/components/CardsView'
import MapView from '../../Jobs/components/MapView'
import CalendarView from '../../Jobs/components/CalendarView'
import KanbanView from '../../Jobs/components/KanbanView'
import ExportJobs from '../../Jobs/components/ExportJobs'

import ActionBar from '../../components/ActionBar'
import LabelColumn from '../../components/LabelColumn'
import FilterForm from '../../components/forms/FilterForm'

import InplaceForm from 'components/InplaceForm'
import { ActionButtons } from 'components/lib/Button'

import * as acl from 'utils/acl.service'
import { fullAddress, fullName, jobStatus } from 'utils'

import { trackEvent } from 'api/clientHub'
import { BID_WALKTHROUGH_DOWNLOAD_WALKTHROUGH, USER_COMPLETE_WALKTHROUGH_SUCCESS } from 'store/analyticsActions/types/walkthroughsTypes'


const FileDownload = function (data, filename, mime) {
  var blob = new Blob([data], { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    var blobURL = window.URL.createObjectURL(blob)
    var tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}

const SpanLink = styled.span`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

class WalkthroughList extends Component {
  state = {
    showModal: false,
    selectedJob: null,

    viewMode: 'table'
  }

  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  async componentDidMount() {
    await this.props.getServiceTemplates()
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.walkthroughId
    ) {
      const res = await axiosClient.get(
        `/api/jobs/${this.props.match.params.walkthroughId}`
      )
      if (res && res.data) {
        this.setState({
          showModal: true,
          selectedJob: res.data
        })
      }
    }
  }

  _triggerForceFetch = () => {
    if (this.dataScreen.current) {
      this.dataScreen.current.forceFetch()
    }
  }

  _goToPage = async page => {
    this.props.history.push(`/dashboard/walkthroughs?page=${page}`)
    this.props.getWalkthroughs({ page: page })
  }

  createWalkthrough = () =>
    this.props.history.push('/dashboard/new-job/Walkthrough')

  editWalkthough = job => this.props.history.push(`/dashboard/jobs/${job._id}`)

  cancelWalkthrough = async job => {
    let removed = await this.props.cancelWalkthrough(job._id)

    if (removed.error) {
      return networkErrorToast(removed)
    } else {
      toast(`${job.name || job.code || 'Walkthrough'} was cancelled.`)
      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
    }
  }

  archiveWalkthrough = async job => {
    let archived = await this.props.archiveWalkthrough(job._id)

    if (archived.error) {
      return networkErrorToast(archived)
    } else {
      toast(`${job.name || job.code || 'Walkthrough'} was archived.`)
      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
    }
  }

  updateLocationAreas = async job => {
    // safe check to ensure that the job is completed
    if (!job.completedAt || !job.areas || !job.areas.length) return

    let newAreas = job.areas.map(area => {
      let newArea = { ...area }
      delete newArea._id
      delete newArea.isAutoGenerated
      return newArea
    })
    let payload = {
      _id: job.location._id,
      areas: newAreas
    }

    let completed = await this.props.updateLocation(payload)

    if (completed.error) {
      return networkErrorToast(completed)
    } else {
      toast(
        `${job.name || job.code || 'Walkthrough'} areas were added to Location.`
      )
      this.setState({
        showModal: false
      })
    }
  }

  completeWalkthrough = async job => {
    let completed = await this.props.completeWalkthrough(job._id)

    if (completed.error) {
      return networkErrorToast(completed)
    } else {
      let params = {
        Job_ID: job._id
      }
      trackEvent({ typeEvent: USER_COMPLETE_WALKTHROUGH_SUCCESS, params })
      toast(`${job.name || job.code || 'Walkthrough'} was completed.`)

      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
    }
  }

  startWalkthrough = async job => {
    let started = await this.props.startWalkthrough(job._id)

    if (started.error) {
      return networkErrorToast(started)
    } else {
      toast(`${job.name || job.code || 'Walkthrough'} was started.`)
      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
    }
  }

  createService = job => {
    job._id = null
    job.completedAt = null
    job.completedBy = null
    job.team = []

    this.props.history.push({
      pathname: '/dashboard/new-job/',
      state: { job }
    })
  }

  _cloneWalkthrough = async includePictures => {
    const job = this.state.selectedJob
    let cloned = await this.props.cloneWalkthrough(job._id, includePictures)

    if (cloned.error) {
      return networkErrorToast(cloned)
    } else {
      toast(`${job.name || job.code || 'Walkthrough'} was cloned.`)
      this.setState({
        showModal: false
      })
      this._triggerForceFetch()
    }
  }

  _openJobModal = job => {
    this.setState({
      showModal: true,
      selectedJob: job
    })
  }

  _toggleModal = () => {
    this.setState({
      showModal: false
    })
  }

  _toggleViewMode = viewMode => {
    this.setState({
      viewMode
    })
  }

  exportJobs = jobs => {
    this.setState({
      exportJobsComponent: <ExportJobs jobs={jobs} />
    })

    setTimeout(() => {
      this.setState({
        exportJobsComponent: null
      })
    }, 500)
  }

  renderJobs = columns => {
    switch (this.state.viewMode) {
      case 'table': {
        return (
          <DataScreen
            ref={this.dataScreen}
            entity="Walkthrough"
            url="/api/jobs/find?type=Walkthrough"
            onRowClick={job => this._openJobModal(job)}
            columns={columns}
            filterData={this.state.filterData}
          />
        )
      }
      case 'calendar': {
        return (
          <DataScreen
            ref={this.dataScreen}
            usePages={false}
            //            useQueryForPages={false}
            entity="Walkthrough"
            url="/api/jobs/find?type=Walkthrough&limit=1000"
            onRowClick={job => this._openJobModal(job)}
            columns={columns}
            component={<CalendarView onOpen={job => this._openJobModal(job)} />}
            filterData={this.state.filterData}
          />
        )
      }
      case 'kanban': {
        return (
          <DataScreen
            ref={this.dataScreen}
            usePages={false}
            //            useQueryForPages={false}
            entity="Walkthrough"
            url="/api/jobs/find?type=Walkthrough&limit=1000"
            onRowClick={job => this._openJobModal(job)}
            columns={columns}
            component={
              <KanbanView
                onOpen={job => this._openJobModal(job)}
                viewHeight="290px"
              />
            }
            filterData={this.state.filterData}
          />
        )
      }
      case 'map': {
        return (
          <MapView
            currentCompany={this.props.currentCompany}
            onOpen={job => this._openJobModal(job)}
          />
        )
      }
      case 'cards': {
        return (
          <CardsView
            currentCompany={this.props.currentCompany}
            onOpen={job => this._openJobModal(job)}
            onClone={this._cloneWalkthrough}
            onEdit={job => this.editWalkthough(job)}
            onCancel={job => this.cancelWalkthrough(job)}
            onRemove={job => this.archiveWalkthrough(job)}
            onConvert={job => this.createService(job)}
          />
        )
      }
      default: {
        return (
          <DataScreen
            ref={this.dataScreen}
            entity="Walkthrough"
            url="/api/jobs/find?type=Walkthrough"
            onRowClick={job => this._openJobModal(job)}
            columns={columns}
            filterData={this.state.filterData}
          />
        )
      }
    }
  }

  requestPDF = async job => {


    const fileName = `${this.props.currentCompany ? this.props.currentCompany.name + '-' : ''
      }Walkthrough${job && job.code ? '-' + job.code : ''}.pdf`

    await axiosClient
      .get(`api/jobs/${job._id}/pdf`, {
        responseType: 'blob'
      })
      .then(result => {
        FileDownload(result.data, fileName)
      })

    trackEvent({ typeEvent: BID_WALKTHROUGH_DOWNLOAD_WALKTHROUGH, params: { ID: job._id } })
  }

  updateWalkthroughName = async data => {
    if (!data || !data.jobName || !data.jobName.length) {
      return
    }

    await this.props.updateWalkthrough({
      _id: this.state.selectedJob._id,
      name: data.jobName
    })
    this.setState({
      selectedJob: { ...this.state.selectedJob, name: data.jobName }
    })
    this._triggerForceFetch()
  }

  _createProposal = async jobId => {
    let proposal = {}
    const job = await this.props.getWalkthrough(jobId)
    if (job && job.payload && job.payload.data) {
      proposal.job = job.payload.data
      proposal.labels = job.payload.data.labels
      proposal.client = job.payload.data.client
      proposal.location = job.payload.data.location
    }

    proposal.merchant = this.props.currentCompany._id

    const result = await this.props.createProposal(proposal)
    if (result.error) {
      return networkErrorToast(result, 'Unable to Create Proposal')
    }

    if (result.payload && result.payload.data) {
      this.props.history.push(
        `/dashboard/edit-proposal/${result.payload.data._id}`
      )
    }
    toast('Proposal Created')
  }

  _createEstimate = async job => {
    this.props.history.push(`/dashboard/new-estimate`, {
      job
    })
  }

  _showArchivedWalkthroughs = () => {
    this.props.history.push('/dashboard/walkthroughs/archived/')
  }

  render() {
    let { profile, services } = this.props
    const { selectedJob, showModal } = this.state

    const columns = [
      {
        header: 'Labels',
        width: '5%',
        render: item => {
          const jobServices = item.categories.map(category =>
            _.find(services, {
              name: category
            })
          )
          return (
            <LabelColumn
              id={item._id}
              services={jobServices}
              labels={item.labels}
            />
          )
        }
      },
      {
        header: '',
        render: job => {
          return <StatusIcon status={jobStatus(job)} />
        }
      },
      {
        header: 'Name',
        render: job => (job.name ? `${job.code} - ${job.name}` : `${job.code}`),
        sortable: true,
        sortField: 'code'
      },
      {
        header: 'Client',
        accessor: 'client.name',
        sortable: true
      },

      {
        header: 'Location',
        render: job =>
          job.location &&
          job.location.address &&
          fullAddress(job.location.address),
        sortField: 'location.address.formattedAddress',
        sortable: true
      },
      {
        header: 'Performed By',
        render: item => {
          const { completedBy } = item
          if (completedBy) {
            if (completedBy.name) {
              return fullName(completedBy.name)
            }

            return ''
          } else {
            return null
          }
        },
        sortable: true,
        sortField: 'completedBy.name.first'
      },
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        },
        sortable: true,
        sortField: 'createdAt'
      }
    ]

    const viewSwitcher = (
      <ButtonGroup style={{ marginRight: 8 }}>
        <Button
          color="light"
          onClick={() => this._toggleViewMode('table')}
          active={this.state.viewMode === 'table'}
        >
          <FaTable />
        </Button>
        <Button
          color="light"
          onClick={() => this._toggleViewMode('kanban')}
          active={this.state.viewMode === 'kanban'}
        >
          <FaTrello />
        </Button>
        {/* <Button
          color="light"
          onClick={() => this._toggleViewMode('cards')}
          active={this.state.viewMode === 'cards'}
          >
          <FaList />
        </Button>*/}

        {/*<Button
          color="light"
          onClick={() => this._toggleViewMode('map')}
          active={this.state.viewMode === 'map'}
        >
          <FaMap />
        </Button> */}
        <Button
          color="light"
          onClick={() => this._toggleViewMode('calendar')}
          active={this.state.viewMode === 'calendar'}
        >
          <FaCalendar />
        </Button>
      </ButtonGroup>
    )

    const actionBar = (
      <ActionBar>
        <div />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FilterForm
            applyFilters={data =>
              this.setState({
                filterData: data
              })
            }
            locationFilters
            serviceFilters
          />
          <div>{viewSwitcher}</div>
          <ActionButtons
            buttons={[
              ...(acl.isAllowed('jobs', 'update')
                ? [
                  {
                    title: 'Create Walkthrough',
                    disabled: !profile,
                    onClick: this.createWalkthrough
                  }
                ]
                : [])
            ]}
            options={[
              {
                title: 'See archived walkthroughs',
                onClick: this._showArchivedWalkthroughs
              }
            ]}
          />
        </div>
      </ActionBar>
    )

    const initialJobName =
      selectedJob && selectedJob.name
        ? selectedJob.name.length
          ? selectedJob.name
          : 'Untitled'
        : 'Walkthrough'

    let renderedJobs = this.renderJobs(columns)

    return (
      <Container fluid style={{ marginTop: 20, height: 'calc(100% - 128px)' }}>
        <Modal
          isOpen={showModal}
          toggle={this._toggleModal}
          className="big-modal"
          fade={false}
        >
          {selectedJob && (
            <>
              <ModalHeader toggle={this._toggleModal}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start'
                  }}
                >
                  {' '}
                  <div style={{ paddingTop: 5 }}>
                    {selectedJob.code ? selectedJob.code + ' - ' : null}{' '}
                  </div>
                  <InplaceForm
                    fieldName="jobName"
                    initialValues={{ jobName: initialJobName }}
                    header
                    onSubmit={this.updateWalkthroughName}
                  />
                </div>
                {selectedJob.client && (
                  <>
                    <SpanLink
                      className="small"
                      onClick={() => {
                        this.props.history.push(
                          `/dashboard/client-details/${selectedJob.client._id}`
                        )
                      }}
                    >
                      {selectedJob.client.name}
                    </SpanLink>
                    <span className="small"> at </span>
                    <SpanLink
                      className="small"
                      onClick={() => {
                        this.props.history.push(
                          `/dashboard/location-details/${selectedJob.location._id}`
                        )
                      }}
                    >
                      {selectedJob.location.name ||
                        fullAddress(selectedJob.location.address)}
                    </SpanLink>
                  </>
                )}
              </ModalHeader>
              <ModalBody style={{ backgroundColor: '#f0f0f0' }}>
                <JobDetailedView
                  jobId={selectedJob && selectedJob._id}
                  onStart={() => this.startWalkthrough(selectedJob)}
                  onOpen={() => this._openJobModal(selectedJob)}
                  onClone={this._cloneWalkthrough}
                  onEdit={() => this.editWalkthough(selectedJob)}
                  onRemove={() => this.archiveWalkthrough(selectedJob)}
                  onUpdateLocationAreas={this.updateLocationAreas}
                  onCancel={() => this.cancelWalkthrough(selectedJob)}
                  onComplete={() => this.completeWalkthrough(selectedJob)}
                  onConvert={() => this.createService(selectedJob)}
                  onPDF={() => this.requestPDF(selectedJob)}
                  onCreateProposal={this._createProposal}
                  onCreateEstimate={this._createEstimate}
                  showOptions={true}
                />
              </ModalBody>
              <ModalFooter />
            </>
          )}
        </Modal>

        {actionBar}

        {renderedJobs}

        {this.state.exportJobsComponent}
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  currentCompany: state.user.currentCompany,
  profile: state.user.profile,
  services: state.library.services
})

export default connect(mapStateToProps, {
  createProposal,
  getWalkthrough,
  getWalkthroughs,
  createWalkthrough,
  cloneWalkthrough,
  cancelWalkthrough,
  completeWalkthrough,
  updateWalkthrough,
  updateLocation,
  startWalkthrough,
  archiveWalkthrough,
  createEstimate,
  getServiceTemplates
})(WalkthroughList)
