import React, { Component } from 'react'
import axios from 'axios'
import { Button } from 'reactstrap'

import Bug from 'assets/img/bug-fixing.svg'
import LevelContainer from 'components/LevelContainer'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      info: null
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info })
  }

  goToHomepage = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_SLACK_INCOMING_WEBHOOK,
        {
          text: `${
            process.env.NODE_ENV === 'development' ? '[DEV]' : ''
          } Route web crash report:\n
          Error : \`\`\`${this.state.error}\`\`\` \n
          Bug Info : \`\`\`${
            this.state.info.componentStack
              ? this.state.info.componentStack
              : JSON.stringify(this.state.info, null, 2)
          }\`\`\` \n
          `,
          mrkdwn: true
        },
        { headers: { 'Content-Type': 'text/plain' } }
      )
    } catch (e) {
      console.error('## error ', e)
    }
    window.location.replace('/dashboard')
  }

  render() {
    if (this.state.hasError) {
      // app crash fallback UI
      return (
        <div className="container" style={{ marginTop: 20 }}>
          <LevelContainer>
            <h1 className="text-dark">Something went wrong.</h1>
            <Button
              className="cta btn"
              size={'md'}
              color={'primary'}
              onClick={this.goToHomepage}
            >
              Go to Homepage
            </Button>
          </LevelContainer>
          <img
            style={{ width: '56%', marginLeft: '22%' }}
            src={Bug}
            alt="bug"
          />
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
