import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'
import * as actions from 'api'
import DataScreen from 'components/DataScreen'
import LevelContainer from 'components/LevelContainer'
import { connectConfirmation } from 'components/confirm'
import DateWithTooltip from 'components/DateWithTooltip'
import LabelColumn from '../../components/LabelColumn'
import LogoDropZone from '../../components/LogoDropZone'
import ClientModal from 'screens/Dashboard/components/modals/ClientModal'
import { ActionButtons } from 'components/lib/Button'
import * as acl from 'utils/acl.service'
import FilterForm from '../../components/forms/FilterForm'
import Icon from 'components/icons'
import stopEventBubbling from 'utils/stopEventBubbling'
import { trackEvent } from 'api/clientHub'
import { BID_CLIENT_CREATE_CLIENT } from 'store/analyticsActions/types/clientTypes'
import BulkImportModal from '../../components/modals/BulkImport/BulkImportModal'

const ActionContainer = styled(LevelContainer)`
  margin-bottom: 20px;
`

class ClientList extends Component {
  state = {
    showClientModal: false,
    sortBy: 'name',
    showImportModal: false
  }

  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  _onClientLogoDrop = (client, logo) => {
    client.logo = logo
    this.submitClient(client)
  }

  submitClient = async client => {
    const resp = await this.props.submitClient(client)

    trackEvent({ typeEvent: BID_CLIENT_CREATE_CLIENT, params: { ID: resp.payload.data._id } })

    if (this.dataScreen.current) {
      this.dataScreen.current.forceFetch()
    }
  }

  _selectClient = client => {
    this.props.history.push(`/dashboard/client-details/${client._id}`)
  }

  _toggleClientModal = () => {
    const { showClientModal } = this.state
    this.setState({
      showClientModal: !showClientModal
    })
  }

  _toggleImportModal = () => {
    const { showImportModal } = this.state
    this.setState({
      showImportModal: !showImportModal
    })
  }

  _saveClient = async values => {

    await this.submitClient(values)

    this._toggleClientModal()
  }

  _showArchivedClients = () => {
    this.props.history.push('/dashboard/clients/archived/')
  }

  _toggleActive = client => {
    const newClient = {
      ...client,
      active: client.active ? false : true
    }

    this.submitClient(newClient)
  }

  render() {
    const columns = [
      {
        header: 'Labels',
        width: '5%',
        render: item => {
          return <LabelColumn id={item._id} labels={item.labels} />
        }
      },
      {
        header: '',
        width: '64px',
        render: client => (
          <LogoDropZone
            value={client.logo}
            onDrop={logo => this._onClientLogoDrop(client, logo)}
          />
        )
      },
      { header: 'Name', accessor: 'name', sortable: true },
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        },
        sortField: 'createdAt',
        sortable: true
      },
      {
        header: 'Options',
        width: '5%',
        render: item => (
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              onClick={e => {
                stopEventBubbling(e)
              }}
            >
              <Icon icon="IoIosMore" color="black" />
            </DropdownToggle>
            <DropdownMenu right>
              {item.active ? (
                <DropdownItem
                  onClick={e => {
                    stopEventBubbling(e)
                    this._toggleActive(item)
                  }}
                >
                  Mark as inactive
                </DropdownItem>
              ) : (
                <DropdownItem
                  onClick={e => {
                    stopEventBubbling(e)
                    this._toggleActive(item)
                  }}
                >
                  Mark as active
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    ]

    return (
      <Container fluid style={{ marginTop: 20 }}>
        <ClientModal
          isOpen={this.state.showClientModal}
          toggle={this._toggleClientModal}
          submitClient={this._saveClient}
        />
        <BulkImportModal importType="client" isOpen={this.state.showImportModal} toggle={this._toggleImportModal} />
        <ActionContainer>
          <div />
          <div>
            <FilterForm
              applyFilters={data =>
                this.setState({
                  filterData: data
                })
              }
              clientFilters
            />
            <ActionButtons
              buttons={[
                {
                  title: 'Add Client',
                  onClick: this._toggleClientModal
                }
              ]}
              options={[
                {
                  title: 'Import Clients',
                  onClick: this._toggleImportModal
                },
                ...(acl.isAllowed('clients', 'archivedList')
                  ? [
                    {
                      title: 'See archived clients',
                      onClick: this._showArchivedClients
                    }
                  ]
                  : [])
              ]}
            />
          </div>
        </ActionContainer>
        <DataScreen
          ref={this.dataScreen}
          entity="Client"
          url={`/api/clients/find`}
          onRowClick={client => this._selectClient(client)}
          columns={columns}
          filterData={this.state.filterData}
        />
      </Container>
    )
  }
}

export default connect(null, actions)(connectConfirmation(ClientList))
