import React from 'react'
import styled from 'styled-components'

import { ButtonGroup, Icon, Question, Title } from './ui'
import { InputState, ToggleState } from '../../../../../hooks'

const ServiceButton = styled.label`
  align-items: center;
  background-color: #0a5ad1;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.25rem;
  font-weight: 500;
  flex-flow: column nowrap;
  height: 144px;
  justify-content: center;
  line-height: 1.5;
  outline: none;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  width: 144px;

  :first-of-type {
    margin-right: 2rem;
  }

  :active,
  :focus,
  :hover {
    opacity: 0.9;
    transform: translateY(-4px);
  }

  input {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border: none !important;
    display: hidden;
    height: 0 !important;
    outline: none !important;
    width: 0 !important;
  }

  @media screen and (min-width: 32rem) {
    font-size: 1.5rem;
    height: 192px;
    width: 192px;
  }
`

const CommercialIcon = ({
  alt,
  activeColor = '#FFFFFF',
  // inactiveColor = '#0B64EB',
  // isActive = false,
  name,
  onBlur,
  onClick,
  onFocus,
  onMouseOver,
  onMouseOut,
  onTouchCancel,
  onTouchEnd,
  // onTouchMove,
  onTouchStart,
  service,
  title = 'Commercial'
}) => {
  const [value, setValue] = InputState(title.toLowerCase() === service)

  const onSelect = event => {
    setValue(event)
    onClick(event)
  }

  return (
    <ServiceButton
      htmlFor={name}
      onBlur={onBlur}
      onClick={onSelect}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onTouchCancel={onTouchCancel}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    >
      <input name={name} onChange={onSelect} type="checkbox" value={value} />
      <Icon>
        <svg
          alt={alt}
          width="85"
          height="85"
          viewBox="0 0 85 85"
          fill="none"
          title={title}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M79.3333 82.166V28.3326C79.3333 27.6824 78.8906 27.1159 78.2598 26.9582L51 20.1425V12.7493C51 12.1074 50.5684 11.5464 49.9486 11.3804L7.44857 0.0470261C7.02384 -0.0664179 6.56938 0.024199 6.22074 0.292591C5.87142 0.560291 5.66667 0.97533 5.66667 1.41596V82.166H0V84.9993H85V82.166H79.3333ZM8.5 3.25735L48.1667 13.8339V82.166H41.0833V66.5826C41.0833 65.8003 40.449 65.166 39.6667 65.166H17C16.2177 65.166 15.5833 65.8003 15.5833 66.5826V82.166H8.5V3.25735ZM18.4167 67.9993H26.9167V82.166H18.4167V67.9993ZM29.75 82.166V67.9993H38.25V82.166H29.75ZM59.5 82.166V70.8326H68V82.166H59.5ZM70.8333 82.166V69.416C70.8333 68.6336 70.199 67.9993 69.4167 67.9993H58.0833C57.301 67.9993 56.6667 68.6336 56.6667 69.416V82.166H51V23.0637L76.5 29.4387V82.166H70.8333Z"
              fill={activeColor}
            />
            <path
              d="M18.4167 59.5H24.0833C24.8657 59.5 25.5 58.8657 25.5 58.0833V52.4167C25.5 51.6343 24.8657 51 24.0833 51H18.4167C17.6343 51 17 51.6343 17 52.4167V58.0833C17 58.8657 17.6343 59.5 18.4167 59.5ZM19.8333 53.8333H22.6667V56.6667H19.8333V53.8333Z"
              fill={activeColor}
            />
            <path
              d="M32.5833 59.5H38.25C39.0323 59.5 39.6666 58.8657 39.6666 58.0833V52.4167C39.6666 51.6343 39.0323 51 38.25 51H32.5833C31.8009 51 31.1666 51.6343 31.1666 52.4167V58.0833C31.1666 58.8657 31.8009 59.5 32.5833 59.5ZM34 53.8333H36.8333V56.6667H34V53.8333Z"
              fill={activeColor}
            />
            <path
              d="M60.9167 53.8333H55.25C54.4677 53.8333 53.8334 54.4676 53.8334 55.25V60.9166C53.8334 61.699 54.4677 62.3333 55.25 62.3333H60.9167C61.6991 62.3333 62.3334 61.699 62.3334 60.9166V55.25C62.3334 54.4676 61.6991 53.8333 60.9167 53.8333ZM59.5 59.5H56.6667V56.6666H59.5V59.5Z"
              fill={activeColor}
            />
            <path
              d="M72.25 53.8333H66.5833C65.8009 53.8333 65.1666 54.4676 65.1666 55.25V60.9166C65.1666 61.699 65.8009 62.3333 66.5833 62.3333H72.25C73.0323 62.3333 73.6666 61.699 73.6666 60.9166V55.25C73.6666 54.4676 73.0323 53.8333 72.25 53.8333ZM70.8333 59.5H68V56.6666H70.8333V59.5Z"
              fill={activeColor}
            />
            <path
              d="M60.9167 42.5H55.25C54.4677 42.5 53.8334 43.1343 53.8334 43.9167V49.5833C53.8334 50.3657 54.4677 51 55.25 51H60.9167C61.6991 51 62.3334 50.3657 62.3334 49.5833V43.9167C62.3334 43.1343 61.6991 42.5 60.9167 42.5ZM59.5 48.1667H56.6667V45.3333H59.5V48.1667Z"
              fill={activeColor}
            />
            <path
              d="M72.25 42.5H66.5833C65.8009 42.5 65.1666 43.1343 65.1666 43.9167V49.5833C65.1666 50.3657 65.8009 51 66.5833 51H72.25C73.0323 51 73.6666 50.3657 73.6666 49.5833V43.9167C73.6666 43.1343 73.0323 42.5 72.25 42.5ZM70.8333 48.1667H68V45.3333H70.8333V48.1667Z"
              fill={activeColor}
            />
            <path
              d="M60.9167 31.1667H55.25C54.4677 31.1667 53.8334 31.801 53.8334 32.5834V38.25C53.8334 39.0324 54.4677 39.6667 55.25 39.6667H60.9167C61.6991 39.6667 62.3334 39.0324 62.3334 38.25V32.5834C62.3334 31.801 61.6991 31.1667 60.9167 31.1667ZM59.5 36.8334H56.6667V34H59.5V36.8334Z"
              fill={activeColor}
            />
            <path
              d="M72.25 31.1667H66.5833C65.8009 31.1667 65.1666 31.801 65.1666 32.5834V38.25C65.1666 39.0324 65.8009 39.6667 66.5833 39.6667H72.25C73.0323 39.6667 73.6666 39.0324 73.6666 38.25V32.5834C73.6666 31.801 73.0323 31.1667 72.25 31.1667ZM70.8333 36.8334H68V34H70.8333V36.8334Z"
              fill={activeColor}
            />
            <path
              d="M18.4167 48.1667H24.0833C24.8657 48.1667 25.5 47.5324 25.5 46.75V41.0834C25.5 40.301 24.8657 39.6667 24.0833 39.6667H18.4167C17.6343 39.6667 17 40.301 17 41.0834V46.75C17 47.5324 17.6343 48.1667 18.4167 48.1667ZM19.8333 42.5H22.6667V45.3334H19.8333V42.5Z"
              fill={activeColor}
            />
            <path
              d="M32.5833 48.1667H38.25C39.0323 48.1667 39.6666 47.5324 39.6666 46.75V41.0834C39.6666 40.301 39.0323 39.6667 38.25 39.6667H32.5833C31.8009 39.6667 31.1666 40.301 31.1666 41.0834V46.75C31.1666 47.5324 31.8009 48.1667 32.5833 48.1667ZM34 42.5H36.8333V45.3334H34V42.5Z"
              fill={activeColor}
            />
            <path
              d="M18.4167 36.8333H24.0833C24.8657 36.8333 25.5 36.199 25.5 35.4166V29.75C25.5 28.9676 24.8657 28.3333 24.0833 28.3333H18.4167C17.6343 28.3333 17 28.9676 17 29.75V35.4166C17 36.199 17.6343 36.8333 18.4167 36.8333ZM19.8333 31.1666H22.6667V34H19.8333V31.1666Z"
              fill={activeColor}
            />
            <path
              d="M32.5833 36.8333H38.25C39.0323 36.8333 39.6666 36.199 39.6666 35.4166V29.75C39.6666 28.9676 39.0323 28.3333 38.25 28.3333H32.5833C31.8009 28.3333 31.1666 28.9676 31.1666 29.75V35.4166C31.1666 36.199 31.8009 36.8333 32.5833 36.8333ZM34 31.1666H36.8333V34H34V31.1666Z"
              fill={activeColor}
            />
            <path
              d="M18.4167 25.5H24.0833C24.8657 25.5 25.5 24.8657 25.5 24.0833V18.4167C25.5 17.6343 24.8657 17 24.0833 17H18.4167C17.6343 17 17 17.6343 17 18.4167V24.0833C17 24.8657 17.6343 25.5 18.4167 25.5ZM19.8333 19.8333H22.6667V22.6667H19.8333V19.8333Z"
              fill={activeColor}
            />
            <path
              d="M32.5833 25.5H38.25C39.0323 25.5 39.6666 24.8657 39.6666 24.0833V18.4167C39.6666 17.6343 39.0323 17 38.25 17H32.5833C31.8009 17 31.1666 17.6343 31.1666 18.4167V24.0833C31.1666 24.8657 31.8009 25.5 32.5833 25.5ZM34 19.8333H36.8333V22.6667H34V19.8333Z"
              fill={activeColor}
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="85" height="85" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Icon>

      {title}
    </ServiceButton>
  )
}

/**
 * Set this to true if you want the Residential option to display.
 * Currently false, so ResidentialIcon will not render.
 */
const SHOW_RESIDENTIAL = false

/**
 * ResidentialIcon Component
 * Represents a selectable Residential Commercial Cleaning option.
 * Wrapped in a condition so it can be easily toggled on or off.
 */
const ResidentialIcon = ({
  alt,
  activeColor = '#FFFFFF',
  name,
  onBlur,
  onClick,
  onFocus,
  onMouseOver,
  onMouseOut,
  onTouchCancel,
  onTouchEnd,
  onTouchStart,
  service,
  title = 'Residential'
}) => {
  const [value, setValue] = InputState(title === service)
  const onSelect = event => {
    setValue(event)
    onClick(event)
  }
  return (
    <ServiceButton
      htmlFor={name}
      onBlur={onBlur}
      onClick={onSelect}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onTouchCancel={onTouchCancel}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    >
      <input name={name} onChange={onSelect} type="checkbox" value={value} />
      <Icon>
        <svg
          alt={alt}
          width="77"
          height="77"
          viewBox="0 0 77 77"
          fill="none"
          title={title}
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Residential house icon paths */}
          <g clipPath="url(#clip0)">
            <path
              d="M75.7068 34.422L69.2019 28.495V8.84147C69.2019 7.59097 68.1881 6.57704 66.9375 6.57704H51.2796C50.0291 6.57704 49.0151 7.59082 49.0151 8.84147V10.1008L41.1593 2.9427C39.6429 1.56076 37.3563 1.56091 35.8405 2.94255L1.29304 34.4222C0.078481 35.529 -0.322912 37.2345 0.27053 38.7668C0.864122 40.2991 2.30938 41.2891 3.95269 41.2891H9.47038V72.8292C9.47038 74.0797 10.4842 75.0936 11.7348 75.0936H30.6709C31.9214 75.0936 32.9354 74.0799 32.9354 72.8292V53.6791H44.0644V72.8294C44.0644 74.0799 45.0782 75.0938 46.3289 75.0938H65.2642C66.5147 75.0938 67.5287 74.08 67.5285 72.8294V41.2893H73.0474C74.6906 41.2893 76.136 40.2991 76.7296 38.7669C77.3229 37.2345 76.9216 35.529 75.7068 34.422ZM65.2642 36.7604C64.0137 36.7604 62.9998 37.7742 62.9998 39.0249V70.5649H48.5934V51.4146C48.5934 50.1641 47.5795 49.1502 46.329 49.1502H30.6709C29.4204 49.1502 28.4065 50.164 28.4065 51.4146V70.5649H13.9991V39.0249C13.9991 37.7744 12.9853 36.7604 11.7347 36.7604H5.45089L38.5001 6.64622L49.7548 16.9015C50.4177 17.5055 51.3746 17.6611 52.1947 17.2991C53.0149 16.9367 53.5442 16.1243 53.5442 15.2277V11.1059H64.6734V29.4951C64.6734 30.1323 64.9417 30.7397 65.4127 31.1689L71.5491 36.7604H65.2642Z"
              fill={activeColor}
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="77" height="77" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Icon>
      {title}
    </ServiceButton>
  )
}

/**
 * ServiceStage Component
 * Displays the question and service options.
 * Currently shows only the Commercial option. Residential is conditionally displayed based on SHOW_RESIDENTIAL.
 */
export const ServiceStage = ({ onService, service }) => {
  const [commercialToggled, setCommercialToggled] = ToggleState(false)
  const [residentialToggled, setResidentialToggled] = ToggleState(false)

  return (
    <Question>
      <Title>What type of cleaning do you perform?</Title>

      <ButtonGroup style={{ marginTop: '2rem' }}>
        <CommercialIcon
          alt={`icon button to select commercial bid`}
          isActive={commercialToggled}
          name={`commercial`}
          onBlur={event => setCommercialToggled(false)}
          onFocus={event => setCommercialToggled(true)}
          onClick={event => onService(event, 'commercial')}
          onMouseOver={event => setCommercialToggled(true)}
          onMouseOut={event => setCommercialToggled(false)}
          onTouchCancel={event => setCommercialToggled(false)}
          onTouchEnd={event => setCommercialToggled(false)}
          onTouchStart={event => setCommercialToggled(true)}
          service={service}
          title={`Commercial`}
        />

        {SHOW_RESIDENTIAL && (
          <ResidentialIcon
            alt={`icon button to select residential bid`}
            isActive={residentialToggled}
            name={`residential`}
            onBlur={event => setResidentialToggled(false)}
            onFocus={event => setResidentialToggled(true)}
            onClick={event => onService(event, 'residential')}
            onMouseOver={event => setResidentialToggled(true)}
            onMouseOut={event => setResidentialToggled(false)}
            onTouchCancel={event => setResidentialToggled(false)}
            onTouchEnd={event => setResidentialToggled(false)}
            onTouchStart={event => setResidentialToggled(true)}
            service={service}
            title={`Residential`}
          />
        )}
      </ButtonGroup>
    </Question>
  )
}

export default ServiceStage