import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from 'api'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { FiMoreHorizontal } from 'react-icons/fi'
import DateWithTooltip from 'components/DateWithTooltip'
import DataScreen from 'components/DataScreen'

class ArchivedInspections extends Component {
  constructor(props) {
    super(props)
    this.dataScreen = React.createRef()
  }

  unarchiveInspection = async inspection => {
    const unarchived = { ...inspection, archived: false }
    await this.props.unarchiveInspection(unarchived)
    if (this.dataScreen.current) {
      this.dataScreen.current.forceFetch()
    }
  }

  render() {
    const columns = [
      { header: 'Code', accessor: 'code' },
      { header: 'Client', accessor: 'client.name' },
      {
        header: 'Location',
        accessor: 'location.address.fullAddress'
      },
      {
        header: 'Created',
        render: item => {
          const { _id, createdAt } = item
          return <DateWithTooltip id={_id} date={createdAt} />
        }
      },
      {
        header: 'Options',
        render: inspection => (
          <UncontrolledDropdown>
            <DropdownToggle color="link">
              <FiMoreHorizontal size={24} color="black" />
            </DropdownToggle>
            <DropdownMenu right>
              <>
                <DropdownItem
                  onClick={() => this.unarchiveInspection(inspection)}
                >
                  Restore
                </DropdownItem>
              </>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    ]

    return (
      <div className="container" style={{ marginTop: 20 }}>
        <h3
          style={{
            color: 'white',
            marginTop: 20,
            textAlign: 'center'
          }}
        >
          Archived Inspections
        </h3>
        <DataScreen
          ref={this.dataScreen}
          entity="Archived Inspection"
          url="/api/jobs/find?type=Inspection&archived=true"
          columns={columns}
        />
      </div>
    )
  }
}

export default connect(null, actions)(ArchivedInspections)
