import React, { useRef } from 'react'
import { connect } from 'react-redux'
import DataScreen from 'components/DataScreen'
import DateWithTooltip from 'components/DateWithTooltip'
import * as actions from 'api'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { FiMoreHorizontal } from 'react-icons/fi'

const ArchivedLocations = (props) => {

    const dataScreenRef = useRef(null)

    const unarchiveLocations = async (location) => {

        const { updateLocation } = props
        const unarchived = { ...location, archived: false }
        await updateLocation(unarchived)
        if (dataScreenRef.current) {
            dataScreenRef.current.forceFetch()
        }
    }

    const columns = [

        { header: 'Client', accessor: 'name' },
        { header: 'Type', accessor: 'type' },
        { header: 'Address', accessor: 'address.formattedAddress' },
        {
            header: 'Created',
            render: item => {
                const { _id, createdAt } = item
                return <DateWithTooltip id={_id} date={createdAt} />
            }
        },
        {
            header: 'Options',
            render: location => (
                <UncontrolledDropdown>
                    <DropdownToggle color="link">
                        <FiMoreHorizontal size={24} color="black" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <>
                            <DropdownItem onClick={() => unarchiveLocations(location)}>
                                Restore
                            </DropdownItem>
                        </>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ]

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <h3
                style={{
                    color: 'white',
                    marginTop: 20,
                    textAlign: 'center'
                }}
            >
                Archived Locations
            </h3>

            <DataScreen
                ref={dataScreenRef}
                entity="Archived Location"
                url="/api/locations/find?archived=true"
                columns={columns}
            />


        </div>

    )
}


export default connect(null, actions)(ArchivedLocations)
